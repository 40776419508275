import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  min-height: 33.3vh;
  display: flex;
  position: relative;

  @media ${from(Device.Desktop)} {
    min-height: 66.6vh;
  }

  ${({ layout }) => {
    switch (layout) {
        case 'image-left':
            return css `
          background-color: ${brand.grey.grey96};

          ${() => ContentWrapper} {
            @media ${from(Device.Tablet)} {
              order: 1;
              flex-basis: 60%;
            }

            @media ${from(Device.Desktop)} {
              margin-left: 10%;
              flex-basis: 50%;
            }
          }

          ${() => ImageContainer} {
            flex-basis: 40%;
          }
        `;
        case 'image-right':
        default:
            return css `
          ${() => DownArrow} {
            left: 90%;

            @media ${from(Device.Desktop)} {
              left: 60%;
            }
          }

          ${() => ImageContainer} {
            flex-basis: 40%;
          }

          ${() => ContentWrapper} {
            flex-basis: 60%;
          }
        `;
    }
}}
`;
const Title = styled.div `
  color: ${brand.primary.alt};

  font-size: 22px;
  line-height: 25px;
  @media ${from(Device.Tablet)} {
    margin-bottom: 30px;
    font-size: 25px;
  }

  @media ${from(Device.Desktop)} {
    margin-bottom: 20px;
    font-size: 28px;
  }
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;

  @media ${from(Device.Desktop)} {
    ${() => ParagraphStyles.Paragraph} {
      max-width: 85%;
    }
  }
`;
const Split = styled(m.div).attrs(({ inView }) => ({
    initial: 'hidden',
    animate: inView ? 'visible' : 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            y: 25,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
            },
        },
    },
})) `
  display: flex;
  margin: auto;

  @media ${until(Device.Tablet)} {
    flex-direction: column;
  }

  padding: 50px 0px 25px;
  @media ${from(Device.Tablet)} {
    padding: 50px 0px;
  }

  max-width: 90vw;
  @media ${from(Device.Desktop)} {
    max-width: 60vw;
    padding: 15px 0px;
  }
`;
const ImageContainer = styled.div `
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }
`;
const DownArrow = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
    transition: {
        delay: 0.5,
        duration: 1,
    },
})) `
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
  width: 2px;
  color: ${brand.primary.alt};

  background-image: linear-gradient(${brand.primary.alt}, 55%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 2px 20px;
  background-repeat: repeat-y;
  z-index: 2;

  height: 75px;
  left: 10%;
  @media ${from(Device.Desktop)} {
    height: 150px;
    left: 33.3%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    background-color: ${brand.primary.alt};

    width: 10px;
    height: 10px;
    @media ${from(Device.Tablet)} {
      width: 15px;
      height: 15px;
    }
  }
`;
const ButtonsContainer = styled.div `
  display: flex;
  gap: 15px;
  margin-top: 15px;

  @media ${until(Device.Desktop)} {
    flex-direction: column;
  }

  ${() => ButtonStyles.Button} {
    min-width: 150px;
    text-align: center;

    &:last-child {
      svg {
        max-height: 15px;
      }
    }
  }
`;
export const FiftyFiftyTextImageStyles = {
    Container,
    Title,
    Split,
    ContentWrapper,
    ImageContainer,
    ButtonsContainer,
    DownArrow,
};
