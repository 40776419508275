import { HydrateOption } from '@core/enums';
import { imageUrl } from '@helpers/imageUrl';
import withRedux from '@helpers/withRedux';
import { withMotion } from '@hoc/withMotion';
import withWidget from '@hoc/withWidget';
import { useInView } from '@hooks/useInView';
import { ReactComponent as Chevron } from '@img/icons/chevron-right.svg';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import Button, { LinkButton } from '@stories/Components/Buttons/Button/Button';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import { FiftyFiftyTextImageStyles as S } from './FiftyFiftyTextImage.styles';
const FiftyFiftyTextImage = ({ heading, text, layout = 'image-left', image, downArrow, loginText, registrationCta, }) => {
    const [ref, inView] = useInView({ threshold: 0.45 });
    const dispatch = useDispatch();
    const loggedIn = useSelector((x) => x.isLoggedIn);
    return (React.createElement(S.Container, { ref: ref, layout: layout },
        React.createElement(S.Split, { inView: inView },
            React.createElement(S.ContentWrapper, null,
                React.createElement(S.Title, null, heading),
                React.createElement(Paragraph, { size: "small" }, text),
                !!loginText && !!registrationCta && (React.createElement(S.ButtonsContainer, null,
                    !loggedIn && (React.createElement(Button, { branding: "primaryAlt", title: loginText, onClick: () => {
                            dispatch({
                                type: 'APP/SET_LOGIN_MODAL',
                                payload: {
                                    loginType: LoginRedirectType.Default,
                                },
                            });
                        } })),
                    React.createElement(LinkButton, { href: registrationCta.url, branding: "textOnly", title: registrationCta.title, addon: React.createElement(Chevron, null), addonPosition: "right", target: "_blank" })))),
            React.createElement(S.ImageContainer, null,
                React.createElement("img", { src: imageUrl(image.src, { width: 720, dpr: 1 }), sizes: '720px' }))),
        downArrow && React.createElement(S.DownArrow, null)));
};
export default withWidget(withRedux(withMotion(FiftyFiftyTextImage)), 'FiftyFiftyTextImage', {
    hydrate: HydrateOption.InView,
});
